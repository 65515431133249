<template>
  <div>
    <a-form-model ref="form" class="query" :model="queryParam" layout="horizontal" v-bind="grid.form">
      <a-row :gutter="0">
        <a-col v-bind="grid.col">
          <a-form-model-item label="模板类型" prop="smsType">
            <DictSelect placeholder="选择模板类型" field="smsType"  :value.sync="queryParam.smsType"></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col v-bind="grid.col">
          <a-form-model-item label="模板内容" prop="smsContent">
            <a-input placeholder="模板内容" v-model="queryParam.smsContent"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col v-bind="grid.col">
          <a-form-model-item label="模板编号" prop="temp">
            <a-input placeholder="模板编号" v-model="queryParam.temp"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col v-bind="grid.col">
          <a-form-model-item v-bind="grid.btn">
            <a-button type="primary" @click="toSearch(0)" icon="search">查询</a-button>
            <a-button type="default" @click="toSearch(1)" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table :row-selection="rowSelection" :rowKey="record=>record.id" :columns="columns" :data-source="tableData"
      bordered :pagination="page" @change="changeTable" :customRow="changeTableRow">
    </a-table>
  </div>
</template>

<script>
  import * as columns from "./components/columns"
  const qs = require("qs")
  export default {
    data() {
      return {
        grid: this.$grid,
        queryParam: {},
        resetQueryParam: null,
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
        resetPage: null,
        columns: columns.smsColumns,
        tableData: [],
        selectedRowKeys: [],
        selectedRows: [],
      }
    },
    methods: {
      /**
       * 查询
       */
      toSearch(type) {
        if (type == 1) {
          this.resetQueryParam ? this.queryParam = Object.assign({}, this.resetQueryParam) : this.resetQueryParam =
            Object.assign({}, this.queryParam)
        }
        this.selectedRowKeys = []
        this.selectedRows = []
        let postData = Object.assign({}, this.queryParam, {
          pageNumber: type==2?this.page.current:this.page.current=1,
          pageSize:this.page.pageSize
        })
        this.axios.get(`/api/agent/system/messageInfo/list?${qs.stringify(postData)}`).then(res => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        }).catch(err => {})
      },
      /**
       * 更改分页
       */
      changeTable(pagination, filters, sorter) {
        this.page = pagination
        this.toSearch(2)
      },
      /**
       * 点击行选中
       */
      changeTableRow(record, index) {
        return {
          on: {
            click: e => {
              if (this.selectedRowKeys[0] == record.id) {
                this.selectedRowKeys = []
                this.selectedRows = []
              } else {
                this.selectedRowKeys = [record.id]
                this.selectedRows = [record]
              }
            }
          }
        }
      },
      /**
       * 
       */
      toHanlder(name) {
        if (name == "add") {
          this.$refs.activityModal.showInfo(null, "add")
          return;
        }
        if (this.selectedRows.length == 0) {
          this.$notification.info({
            message: "请选择一条记录"
          })
          return
        }
        switch (name) {
          case "edit":
            this.$refs.activityModal.showInfo(this.selectedRows[0], "edit")
            break;
          case "del":
            this.toDelete(this.selectedRows[0])
            break;
        }
      },
      toDelete(row) {
        this.$confirm({
          title: '警告',
          content: `真的删除吗?`,
          okType: 'danger',
          onOk: () => {
            this.axios.post(`/api/agent/system/activity/del/${row.id}`).then(res => {
              this.$notification.success({
                message: res.message
              })
              this.toSearch(0)
            }).catch(err => {})
          }
        })
      },
    },
    created() {
      this.toSearch(1)
    },
    computed: {
      rowSelection() {
        return {
          type: "radio", //"radio"
          selectedRowKeys: this.selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
          }
        }
      }
    }
  }
</script>

<style lang="less" scoped>
</style>
